<template>
    <router-view name="header"></router-view>
    <router-view/>

    <router-view name="footer"></router-view>
    <popup-component
        :show="popups.personalData.show" :name="popups.personalData.name" :width="704">
        <contract-offer-modal></contract-offer-modal>
    </popup-component>
    <popup-component
        :show="popups.contractOfferModal.show" :name="popups.contractOfferModal.name" :width="704">
        <personal-data-modal></personal-data-modal>
    </popup-component>

    <CookieComponent/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PopupComponent from '@/components/PopupComponent.vue';
import CookieComponent from '@/components/CookieComponent.vue';
import ContractOfferModal from './components/modals/ContractOfferModal.vue';
import PersonalDataModal from './components/modals/PersonalDataModal.vue';

export default {
    name: 'App',
    components: {
        PopupComponent,
        ContractOfferModal,
        PersonalDataModal,
        CookieComponent,
    },
    props: {
    },
    data() {
        return {
            viewport: false,
        };
    },
    mounted() {
        this.getCsrf();
        this.viewport = document.querySelector('meta[name=viewport]');
        this.checkWindowSize(); // Проверка размера окна
        this.isMacOs(); // Проверка на мак ос
        window.addEventListener('resize', () => {
            this.checkWindowSize();
        }, true);
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            currentlyActivePopup: (state) => state.popups.currentlyActive,
            isDesktop: (state) => state.isDesktop,
        }),
    },
    watch: {
        $route() {
            document.getElementsByTagName('body')[0].classList.remove('noscroll');
            if (this.currentlyActivePopup) {
                this.close(this.popups[this.currentlyActivePopup]);
            }
        },
    },
    methods: {
        ...mapActions('popups', [
            'close',
        ]),
        ...mapActions(['setIsDesktop']),
        ...mapActions('category', ['getCsrf']),
        setDesktopViewport() {
            document.querySelector('body').classList.remove('mobile-device');
            document.querySelector('body').classList.add('desktop-device');
            this.viewport.setAttribute('content', 'width=device-width, initial-scale=1');
            this.setIsDesktop(true);
        },
        setMobileViewport() {
            document.querySelector('body').classList.remove('desktop-device');
            document.querySelector('body').classList.add('mobile-device');
            if (this.$route.params.landing) {
                this.viewport.setAttribute('content', 'width=375');
            } else {
                this.viewport.setAttribute('content', 'width=320');
            }
            this.setIsDesktop(false);
        },
        checkWindowSize() {
            if (window.screen.width > 991) {
                this.setDesktopViewport();
            }
            window.addEventListener('resize', () => {
                if (window.screen.width > 991) {
                    this.setDesktopViewport();
                } else {
                    this.setMobileViewport();
                }
            });
        },
        isMacOs() {
            const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
            if (+scrollWidth === 0) {
                document.getElementsByTagName('body')[0].classList.add('scroll-mac-os');
            }
        },
    },
};
</script>

<style lang="sass">
@import "~@/assets/sass/style.sass"
#app
  font-family: $default-font
</style>
