<template>
    <div class="offers" v-if="load">
        <div class="offers__item --ghost" v-for="i in 5" :key="i">
            <div class="stat"></div>
            <div class="image-wrapper"></div>
            <div class="title"></div>
            <div class="price"></div>
            <div class="provider"></div>
        </div>
    </div>

    <div class="offers" v-masonry transition-duration="0.3s" gutter="24"  item-selector=".offers__item" id="offers" v-else-if="isDesktop">
        <article v-masonry-tile class="offers__item" v-for="item in items" :key="item.id">
            <router-link :to="'/catalog/'+item.category.code + '/' + item.id">
                <div class="stat">
                    <svg-icon :name="'cart'" :width="16" :height="16" class="svg-icon mr4"></svg-icon>
                    <span class="value">{{ numberFormat(item.orders ) }}</span>
                </div>
                <div class="image-wrapper">
                    <img v-if="item.preview" :src="appPath + '/' +item.preview.path" :alt="item.name">
                </div>
                <h3 class="title">{{ item.name }}</h3>
                <div class="price">
                    От <span class="text-semibold">{{ numberFormat(item.price_min) }} ₽</span>
                    до <span class="text-semibold">{{ numberFormat(item.price_max) }} ₽</span>
                </div>
                <div class="provider">Подрядчик: {{ !item.special ? item.provider.name : 'ZEMS' }}</div>
            </router-link>
        </article>
    </div>
    <div class="offers" v-else>
        <article class="offers__item" v-for="item in items" :key="item.id">
            <router-link :to="'/catalog/'+item.category.code + '/' + item.id">
                <div class="stat">
                    <svg-icon :name="'cart'" :width="16" :height="16" class="svg-icon mr4"></svg-icon>
                    <span class="value">{{ numberFormat(item.orders ) }}</span>
                </div>
                <div class="image-wrapper">
                    <img v-if="item.preview" :src="appPath + '/' +item.preview.path" :alt="item.name">
                </div>
                <h3 class="title">{{ item.name }}</h3>
                <div class="price">
                    От <span class="text-semibold">{{ numberFormat(item.price_min) }} ₽</span>
                    до <span class="text-semibold">{{ numberFormat(item.price_max) }} ₽</span>
                </div>
                <div class="provider">Подрядчик: {{ !item.special ? item.provider.name : 'ZEMS' }}</div>
            </router-link>
        </article>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SvgIcon from '@/components/SvgIcon.vue';
import { numberFormat } from '@/helpers/formatted';
import { scrollTo } from '@/helpers/animate';

export default {
    name: 'OffersItems',
    components: {
        SvgIcon,
    },
    props: {
        popular: {
            default: false,
        },
        code: {
            default: '',
        },
    },
    data() {
        return {
            appPath: process.env.VUE_APP_PATH_BACK,
        };
    },
    created() {
        // watch the params of the route to fetch the data again
        this.$watch(
            () => this.code,
            () => {
                this.fetchData();
            },
            // fetch the data when the view is created and the data is
            // already being observed
            { immediate: false },
        );
    },
    mounted() {
        this.getItems({ code: this.code, popular: false }).then(() => {
            if (!this.isDesktop && this.code) {
                scrollTo(`#${this.code}`);
            } else {
                this.emitter.emit('triggerScrollOffersList');
            }
        });
    },
    computed: {
        ...mapState({
            isDesktop: (state) => state.isDesktop,
        }),
        ...mapState('offer', {
            items: (state) => state.items,
            load: (state) => state.load,
        }),
    },
    methods: {
        ...mapActions('offer', [
            'getItems',
        ]),
        numberFormat(v) {
            return numberFormat(v, 0, '', ' ');
        },
        fetchData() {
            if (this.isDesktop) {
                this.getItems({ code: this.code, popular: false }).then();
            }
            if (!this.isDesktop && this.code) {
                scrollTo(`#${this.code}`);
            }
        },
    },
    unmounted() {
        this.emitter.off('triggerScrollOffersList');
    },
};
</script>

<style lang="sass">
.offers
    margin-left: -12px
    width: 320px
    display: flex
    flex-direction: column
    padding-top: 4px
    padding-bottom: 64px
    gap: 0 24px
    +for-size(992)
        display: flex
        flex-wrap: wrap
        margin: 0
        width: 448px
        padding-top: 0
        padding-bottom: 0
        align-items: flex-start
        flex-direction: row
    +for-size(1440)
        width: 920px
    &__item
        display: block
        background-color: $black2
        text-align: center
        position: relative
        padding-bottom: 20px
        margin-bottom: 24px
        border-radius: 8px
        +for-size(992)
            width: 448px
            padding-bottom: 24px
            margin-bottom: 0
            border-radius: 12px
            margin-bottom: 40px
        &:last-child
            margin-bottom: 0
        .stat
            position: absolute
            width: auto
            height: auto
            display: flex
            align-items: center
            padding: 4px
            border-radius: 4px
            color: $gray-light
            background-color: rgba(#6F6F6F,0.75)
            top: 12px
            left: 12px
            font-size: 12px
            line-height: 16px
            backdrop-filter: blur(4px)
            +for-size(992)
                padding: 4px 8px
                font-size: 16px
                line-height: 24px
        .image-wrapper
            width: 320px
            height: 182px
            display: flex
            align-items: center
            justify-content: center
            overflow: hidden
            border-radius: 8px 8px 0 0
            +for-size(992)
                width: 448px
                height: 256px
                border-radius: 12px 12px 0 0
            img
                width: 100%
        .title
            margin: 20px 0 0
            font-size: 16px
            line-height: 19px
            font-weight: bold
            letter-spacing: 0.025em
            color: $gray3
            word-break: break-word
            +for-size(992)
                margin: 24px 0 0
                font-size: 20px
                line-height: 24px
        .price,.provider
            font-size: 14px
            color: rgba($gray3, 0.5)
            line-height: 16px
            letter-spacing: 0.025em
            margin-top: 12px
            +for-size(992)
                font-size: 16px
                line-height: 19px
                margin-top: 16px
        .provider
            font-weight: bold
            letter-spacing: 0.025em
        &.--ghost
            div
                animation: 1.8s anim-ghost infinite
            .stat
                width: 64px !important
                height: 40px !important
                top: 12px
                left: 12px
                border-radius: 4px
                background-color: #302F2E
                +for-size(992)
                    width: 64px
                    height: 32px
            .image-wrapper
                background-color: transparent
                height: 182px
                +for-size(992)
                    height: 250px
            .title
                width: calc(100% - 32px)
                height: 19px
                border-radius: 4px
                background-color: #302F2E
                margin: 20px auto 12px
                +for-size(992)
                    width: calc(100% - 32px)
                    height: 30px
                    margin: 24px auto
                    margin-bottom: 0
            .price, .provider
                width: 60%
                height: 16px
                background-color: #302F2E
                border-radius: 4px
                margin: 12px auto 0
                +for-size(992)
                    width: 250px
                    height: 19px
                    margin: 16px auto 0
</style>
