/* eslint-disable import/no-extraneous-dependencies */
import { createApp } from 'vue';
import { VueMasonryPlugin } from 'vue-masonry/src/masonry.plugin';
import * as Sentry from '@sentry/vue';
import { createHead } from '@unhead/vue';
import mitt from 'mitt';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import store from './store';
import VueInputMask from './helpers/vue-inputmask';
import SvgIcon from './components/SvgIcon.vue';
import '@/assets/landing-theme.css';

const head = createHead();

const emitter = mitt();

const app = createApp(App);

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', process.env.VUE_APP_API_PATH],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.config.globalProperties.emitter = emitter;
app.use(store)
    .use(router)
    .use(VueInputMask)
    .use(head)
    .use(VueCookies, { expires: '7d' })
    .use(VueMasonryPlugin)
    .component('svg-icon', SvgIcon);
app.mount('#app');

export default app;
