<template>
    <div class="">
        <div class="w-tooltip"
            :style="{display: inline ? 'inline-block' : 'block'}"
            :class="[{'opened': show}, addClassWrap]"
            ref="tooltipWrap"
            v-click-outside="handleClickOutside"
            @focusout="tooltipClose"
            tabindex="0">

            <span ref="tooltipBtn" class="tooltip__btn"
                @click.stop="btnClick"
                @touchstart.prevent="btnClick"
                @focus="mouseOver"
                @blur="mouseLeave"
                :class="addClassBtn"
                @mouseover="mouseOver"
                @mouseleave="mouseLeave">
                <slot name="tooltipBtn"></slot>
            </span>

            <transition name="fade-popup">
                <div class="tooltip"
                     ref="tooltip"
                     v-if="show"
                     :class="[addClass, tooltipDirection, {'--close-btn-big': btnBig}]"
                     :style="addStyle">
                    <div v-if="isBtnClose" class="tooltip__close" @click.prevent="btnClick"></div>
                    <div class="tooltip__content" :style="{'padding':!isPadding ? '0': ''}">
                        <slot name="tooltipContent"></slot>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'TooltipComponent',
    data() {
        return {
            show: false,
            tooltipDirection: this.direction,
        };
    },
    props: {
        inline: { default: false },
        direction: { default: 'top-center-direction' },
        addStyle: { default: '' },
        addClass: { default: '' },
        addClassWrap: { default: '' },
        addClassBtn: { default: '' },
        tempID: { default: '' },
        isChangeablePos: {
            default() {
                return true;
            },
        },
        isBtnClose: {
            default() {
                return true;
            },
        },
        btnBig: {
            default() {
                return false;
            },
        },
        isPadding: {
            default() {
                return true;
            },
        },
        showOnHover: {
            default() {
                return false;
            },
        },
    },
    mounted() {
        // Add touch event listeners for mobile
        document.addEventListener('touchstart', this.handleTouchStart, { passive: true });
    },
    unmounted() {
        // Clean up event listeners
        document.removeEventListener('touchstart', this.handleTouchStart);
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    methods: {
        handleClickOutside(event) {
            if (this.show) {
                const tooltipWrap = this.$refs.tooltipWrap;
                if (!tooltipWrap.contains(event.target)) {
                    this.tooltipClose();
                }
            }
        },
        handleTouchStart(event) {
            if (this.show) {
                const tooltipWrap = this.$refs.tooltipWrap;
                if (!tooltipWrap.contains(event.target)) {
                    this.tooltipClose();
                }
            }
        },
        mouseLeave() {
            if (this.showOnHover) {
                this.tooltipClose();
            }
        },
        mouseOver() {
            if (this.showOnHover) {
                this.tooltipShow();
            }
        },
        btnClick() {
            if (this.show) {
                this.tooltipClose();
            } else {
                this.tooltipShow();
            }
        },
        tooltipClose() {
            this.show = false;
            setTimeout(() => {
                this.$emit('tooltipclose', this.tempID);
            }, 100);
        },
        tooltipShow() {
            this.show = true;
            this.$emit('tooltipshow', this.tempID);
            this.tooltipDirection = this.direction;
            const self = this;
            setTimeout(() => {
                const element = document.querySelector('.w-tooltip.opened .tooltip');
                self.tooltipVisible(element);
            }, 10);
        },
        tooltipVisible(target) {
            const targetPosition = {
                top: window.pageYOffset + target.getBoundingClientRect().top,
                left: window.pageXOffset + target.getBoundingClientRect().left,
                right: window.pageXOffset + target.getBoundingClientRect().right,
                bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
            };
            const windowPosition = {
                top: window.pageYOffset,
                left: window.pageXOffset,
                right: window.pageXOffset + document.documentElement.clientWidth,
                bottom: window.pageYOffset + document.documentElement.clientHeight,
            };
            if (windowPosition.bottom - targetPosition.top < target.offsetHeight && this.isChangeablePos) {
                this.tooltipDirection = this.direction.replace('bottom', 'top');
            }
        },
    },
    emits: ['tooltipshow', 'tooltipclose'],
    directives: {
        /* eslint-disable */
        clickoutside: {
            mounted: function(el, binding, vNode) {
                if (typeof binding.value !== 'function') {
                    const compName = vNode.context.name;
                    let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                    if (compName) { warn += `Found in component '${compName}'` }
                }
                const bubble = binding.modifiers.bubble;
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler;
                // Add both click and touchstart event listeners
                document.addEventListener('click', handler);
                document.addEventListener('touchstart', handler, { passive: true });
            },

            unmounted: function(el, binding) {
                // Remove both event listeners
                document.removeEventListener('click', el.__vueClickOutside__);
                document.removeEventListener('touchstart', el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            },
        },
    }
};

</script>

<style scoped>
    .fade-popup-enter-active, .fade-popup-leave-active {
        transition: opacity .15s;
    }
    .fade-popup-enter, .fade-popup-leave-to {
        opacity: 0;
    }
</style>
