<template>
    <div class="offer-conatiner">
        <div class="popup__close" @click="close" >
            <svg-icon :name="'close'" :width="32" :height="32"></svg-icon>
        </div>
        <div class="popup__text">
        <!-- eslint-disable max-len -->
        <h2>
            Договор оферты
        </h2>
        <p>
            Настоящим ИП Земсков Алексей Васильевич (ИНН 371400551536, ОГРНИП 315370400000046, местонахождение: 155130, Ивановская обл, р-н Комсомольский, с Писцово, ул Юбилейная, д. 1а), зарегистрированный в соответствии с законодательством Российской Федерации, заявляет, что действуя от имени и за счет Партнера, будет считать себя заключившим настоящий договор публичной оферты (далее — «настоящий Договор») со всяким физическим лицом, зарегистрированным на Сайте в сети Интернет по адресу https://ZemsBaza.ru/ в Мобильном приложении ZemsBaza или оформившим заявку на предложение Партнера по реализации Товаров/Работ/Услуг.
            <br><br><b>1. Основные понятия</b>
        </p>
        <br>
        <ul>
                <li>«Сайт» — интернет-сайт, размещенный Агентом в сети Интернет по адресу https://ZemsBaza.ru/.</li>
                <li>«Договор публичной оферты» — положения настоящего Договора, размещенные на Сайте/в Мобильном приложении и предложения Принципала, направленные неопределенному кругу лиц, в том числе Потребителям.</li>
                <li>«Акцепт» — регистрация Потребителя на Сайте/в Мобильном приложении или оформление заявки в соответствии с условиями предложения.</li>
                <li>«Предложение» — предложение Партнера, размещенное на Сайте/в Мобильном приложении, о реализации Товаров/Работ/Услуг на специальных условиях с описанием таковых и направленное на заключение договора между Потребителем и Партнером, на условиях, изложенных в предложении.</li>
                <li>«Агент» — ИП Земсков Алексей Васильевич, сторона в настоящем Договоре, действующее от имени и за счет Партнера на основании соответствующего договора с Партнером.</li>
                <li>«Партнер» — поставщик Товара/Работы/Услуги в соответствии с условиями предложения.</li>
                <li>«Потребитель» — физическое лицо, достигшее возраста 18 лет, осуществившее или имеющее намерение осуществить акцепт.</li>
                <li>«Заявка» — электронный документ (совокупность записей в базе данных Агента), удостоверяющий право Потребителя на приобретение Товаров/Работ/Услуг на условиях предложений, содержащий в себе наименование предложения, территорию исполнения, условия предложения и т.д. Заявка может быть использована только в соответствии с условиями, указанными в ней. Заявка не дает каких-либо дополнительных преференций, если в ней не указано иного.</li>
                <li>«Регистрационные данные» — имя, фамилия, город проживания, номер телефона, адрес электронной почты, указываемые Потребителем самостоятельно при регистрации на Сайте/в Мобильном приложении.</li>
                <li>«Личный кабинет» — персональный раздел Потребителя на Сайте/в Мобильном приложении, при помощи которого можно осуществлять оформление заявок, в полном объеме получать информацию об оформленных заявках, условиях предложений, аннулировать заявки и другое.</li>
                <li>«Мобильное приложение» – электронное приложение для мобильного устройства, предоставляющее возможность оформления заявок. Для установки мобильного приложения ZemsBaza на мобильное устройство, Потребитель самостоятельно совершает необходимые действия с использованием сервисов www.itunes.apple.com и/или www.play.google.com.</li>
                <li>«Лицензия» - простая (неисключительная) непередаваемая отзывная безвозмездная лицензия на использование Мобильного приложения.</li>
                <li>«Мобильное устройство» - электронное устройство (планшет, смартфон, мобильный телефон и т.п.), работающее на базе операционных систем iOS или Android.</li>
                <li>«Служба поддержки» — структурное подразделение Агента, осуществляющее обратную связь с Потребителями. Взаимодействие со службой поддержки осуществляется Потребителем через заполнение специальной формы на Сайте/в Мобильном приложении. Вся переписка между службой поддержки и Потребителем осуществляется на русском языке.</li>
                <li>«Push-уведомление» – информационное уведомление, всплывающее на экране мобильного устройства, передаваемое с сервера, обслуживающего Агента, в Мобильное приложение, установленное на мобильном устройстве Потребителя.</li>
            </ul>
        <br>
        <p>
            <b>2. Заключение настоящего Договора</b>
            <br><br>2.1. Полным и безоговорочным акцептом является регистрация на Сайте Агента https://ZemsBaza.ru/ в сети Интернет/в Мобильном приложении ZemsBaza или оформление заявки в соответствии с настоящим Договором, а также условиями предложения.
            <br><br>2.2. Настоящий Договор считается заключенным с момента совершения Потребителем любого из действий, предусмотренных п. 2.1. настоящего Договора, и приравнен к документу, составленному в письменной форме. Заключение настоящего Договора означает, что Потребитель в необходимой для него степени ознакомился с условиями настоящего Договора, полностью и безоговорочно согласен с ними.
            <br><br>2.3. Условия предложения являются неотъемлемой частью настоящего Договора.
            <br><br>2.4. Агент оставляет за собой право в одностороннем порядке заблокировать полностью или частично доступ к Сайту/Мобильному приложению без предварительного уведомления Потребителя, в том числе по следующим основаниям:
        </p>
        <br>
        <ul>
            <li>однократного нарушения Потребителем настоящего Договора;</li>
            <li>множественной регистрации одним Потребителем;</li>
            <li>нарушения Потребителем порядка оформления заявок;</li>
            <li>выявления факта предоставления Потребителем недостоверной и/или заведомо ложной информации при регистрации на Сайте/в Мобильном приложении;</li>
            <li>совершения Потребителем мошеннических действий, обмана, а также иных действий, повлекших за собой негативные последствия, как для Агента, Партнера, так и для третьих лиц.</li>
        </ul>
        <br>
        <p>
            Настоящий Договор будет считаться расторгнутым с момента блокировки Агентом доступа Потребителя к Сайту/Мобильному приложению.
            <br><br><b>3. Порядок оформления и использования заявок</b>
            <br><br>3.1. Для получения Товаров/Работ/Услуг на условиях предложения, Потребитель оформляет заявку.
            <br><br>3.2. Право на получение Товаров/Работ/Услуг Партнёра на условиях предложения, подтверждается оформленной на Сайте/в Мобильном приложении заявкой, привязанной к номеру телефона Потребителя.
            <br><br>3.3. Под оформлением заявки понимается подтверждение Потребителем с помощью соответствующей электронной формы согласия на условия предложения.
            <br><br>3.4. Оформление заявки Потребителем производится в порядке, предусмотренном специальным разделом на Сайте/в Мобильном приложении.
            <br><br>3.5. После оформления заявки Потребитель получает право на получение Товаров/Работ/Услуг на условиях предложения Партнера.
            <br><br>3.6. Агент вправе установить лимит на количество оформленных Потребителем заявок.
            <br><br>3.7. Обязательства Агента по настоящему Договору действительны до аннулирования заявки Потребителем или Агентом.
            <br><br>3.8. До аннулирования заявки Агент вправе напомнить Потребителю о необходимости использования заявки.
            <br><br>3.9. Подтверждением факта получения Потребителем у Партнера Товара/Работы/Услуги на условиях предложения является погашение заявки Партнером. Потребитель обязан самостоятельно осуществить контроль за погашением заявки Партнером, которое подтверждается полученным от Агента смс-сообщением, направленным на телефонный номер Потребителя.
            <br><br>3.10. Потребитель имеет право самостоятельно аннулировать заявку через личный кабинет.
            <br><br>3.11. Аннулирование Потребителем заявки прекращает обязательства Агента по аннулированной заявке.
            <br><br>3.12. Агент имеет право в одностороннем порядке аннулировать заявку по своей инициативе и/или по требованию Партнера, уведомив об этом Потребителя путем смс-сообщения, при этом обязательства Агента по аннулированной заявке считаются прекращенными.
            <br><br>3.13. Обязательства Агента по настоящему Договору считаются исполненными надлежащим образом и в полном объеме:
        </p>
        <br>
        <ul>
            <li>в случае использования заявки Потребителем;</li>
            <li>в случае, если заявка была использована Потребителем, но не была погашена Партнером, согласно п. 3.9. настоящего Договора.</li>
        </ul>
        <br>
        <p>
            3.14. Оформление заявки не гарантирует, что Партнер реализует Товар/Работы/Услуги Потребителю.
            <br><br>3.15. С момента предъявления Потребителем заявки Партнеру, получения Товара/Работы/Услуги на условиях предложения, все возникшие претензии по качеству и порядку предоставления Товара/Работы/Услуги урегулируются Партнером.
            <br><br>3.16. Агент вправе в любое время изменять перечень предложений и/или делать какие-либо из них недоступными. Перечень в настоящее время реализуемых предложений отображается на Сайте/в Мобильном приложении.
            <br><br><b>4. Конфиденциальность и информация</b>
            <br><br>4.1. При регистрации на Сайте/в Мобильном приложении Потребитель предоставляет Агенту регистрационные данные, которые Агент вправе использовать для выполнения своих обязательств перед Потребителем. Ответственность за достоверность передаваемых Агенту регистрационных данных несет Потребитель.
            <br><br>4.2. Агент использует информацию для:
        </p>
        <br>
        <ul>
            <li>регистрации Потребителя на Сайте/в Мобильном приложении;</li>
            <li>выполнения своих обязательств перед Потребителем;</li>
            <li>для оценки и анализа работы Сайта/Мобильного приложения.</li>
        </ul>
        <br>
        <p>
            4.3. Предоставляя свои регистрационные данные, Потребитель дает Агенту свое добровольное согласие на обработку своих регистрационных данных согласно ст.ст. 3, 15 ФЗ «О персональных данных» 152-ФЗ от 27.07.2006 г. (далее – «Закон»), для целей, определенных настоящим Договором, а также с целью продвижения Агентом своих услуг, различными способами, в том числе путем осуществления анализа регистрационных данных и их передачи Партнерам для выполнения последними обязательств по реализации Товаров/Работ/Услуг.
            <br><br>4.4. Агент обязуется не разглашать полученную от Потребителя информацию. Не считается нарушением предоставление Агентом информации Партнерам, действующим на основании договора с Агентом, для исполнения обязательств перед Потребителем. Не считается нарушением обязательств разглашение информации в соответствии с обоснованными и применимыми требованиями закона. Агент вправе использовать технологию «cookies». «Cookies» не содержат конфиденциальную информацию и не передаются третьим лицам. Агент получает информацию об IP-адресе Потребителя. Данная информация не используется для установления личности Потребителя, за исключением случаев мошеннических действий Потребителя.
            <br><br>4.5. Потребитель вправе обратиться в службу поддержки с требованием о прекращении обработки регистрационных данных, после чего регистрационные данные Потребителя будут удалены.
            <br><br>4.6. Потребитель несет ответственность за сохранение конфиденциальности своих регистрационных данных, в том числе пароля, а также за все действия, осуществляемые с ними.
            <br><br>Потребитель обязуется не передавать третьим лицами пароль от личного кабинета. Пароль для входа в личный кабинет является конфиденциальной информацией, предотвращающей несанкционированный доступ к личному кабинету третьих лиц. Потребитель предпримет все меры для его сохранности. Потребитель несет всю ответственность за разглашение/утерю пароля, а также за все возможные последствия его разглашения/утери с момента его предоставления Потребителю.
            <br><br>Потребитель несет ответственность за все действия, которые совершаются в его личном кабинете и обязан незамедлительно уведомить Агента о любом случае несанкционированного использования его личного кабинета, а также об утере доступа к номеру своего телефона по причинам, не зависящим от Потребителя.
            <br><br>4.7. Агент не несет ответственности за разглашение/утерю Потребителем своих регистрационных данных, пароля, в том числе по причине мошеннических действий третьих лиц. Агент не несёт ответственности за действия третьих лиц, воспользовавшихся регистрационными данными, паролем Потребителя, а также за ущерб, понесенный Потребителем в связи с разглашением/утерей им регистрационных данных, пароля.
            <br><br>4.8. Агент не несет ответственности за сведения, распространенные Потребителем при помощи Сайта/Мобильного приложения в общедоступной форме.
            <br><br>4.9. Потребитель дает свое согласие на получение телефонных звонков, смс-сообщений, электронной почты, push-уведомлений в Мобильном приложении от Агента, а также Партнера, заявки на предложения которого он оформил.
            <br><br>4.10. Потребитель может выбрать параметры рассылки или отказаться от нее в личном кабинете на Сайте/в Мобильном приложении, а также может отписаться от рассылки пройдя по специальной ссылке, размещенной в нижней части электронного письма, которое получает Потребитель.
            <br><br><b>5. Лицензия</b>
            <br><br>5.1. Агент на безвозмездной основе предоставляет Потребителю простую (неисключительную) лицензию на использование Мобильного приложения в пределах и способами, определенными настоящим Договором.
            <br><br>Исключительное право на Мобильное приложение принадлежит Агенту в силу факта создания.
            <br><br>5.2.Лицензия предоставляется на следующих условиях:
            <br><br>5.2.1. Территория действия Лицензии – Российская Федерация;
            <br><br>5.2.2. Срок действия Лицензии – с даты начала использования Потребителем Мобильного приложения и принятия Потребителем условий Лицензии до момента его удаления с Мобильного устройства и/или смены Потребителя мобильного устройства;
            <br><br>5.2.3. Лицензия предоставляет права на использование Мобильного приложения следующими способами и в следующих пределах:
        </p>
        <br>
        <ul>
            <li>копирование и установка Мобильного приложения в память мобильного устройства;</li>
            <li>воспроизведение Мобильного приложения на мобильном устройстве;</li>
            <li>использование Мобильного приложения по прямому функциональному назначению.</li>
        </ul>
        <br>
        <p>
            5.2.4. Потребитель не вправе:
        </p>
        <br>
        <ul>
            <li>выдавать сублицензии, продавать, переуступать, давать в пользование и аренду, распространять, передавать или иным образом предоставлять права на использование Мобильного приложения третьим лицам;</li>
            <li>осуществлять модификацию, усовершенствование, перевод на другие языки, декомпилирование, дизассемблирование, декодирование, эмуляцию, нарушать целостность, восстанавливать исходный код Мобильного приложения или каких-либо его частей;</li>
            <li>использовать Мобильное приложение для нарушения прав третьих лиц, а также целей, противоречащих действующему законодательству.</li>
        </ul>
        <br>
        <p>
            5.3. Лицензия распространяется также на обновления, добавления, дополнительные компоненты, которые могут быть предоставлены, или доступ к которым может быть предоставлен Агентом, а также его Партнерами при использовании Потребителем Мобильного приложения, если право использовать такие обновления, добавления, дополнительные компоненты не передается на основании самостоятельных соглашений.
            <br><br>5.4. Настоящим Договором Потребителю не предоставлены права на:
        </p>
        <br>
        <ul>
            <li>элементы (части, составляющие) Мобильного приложения, включая фотографии, рисунки, графику, анимацию, звуки и иной контент;</li>
            <li>средства индивидуализации лиц, товаров, работ, услуг, в том числе логотипы, товарные знаки, знаки обслуживания, фирменные наименования;</li>
            <li>иное программное обеспечение.</li>
        </ul>
        <br>
        <p>
            <b>6. Ответственность</b>
            <br><br>6.1. Агент не предоставляет Потребителю никаких гарантий в отношении безошибочной и бесперебойной работы Сайта/ Мобильного приложения, его отдельных компонентов или функций, а также соответствия Сайта/Мобильного приложения ожиданиям Потребителя.
            <br><br>Сайт/Мобильное приложение предоставляются «как есть».
            <br><br>6.2. Права и обязанности по соответствующим Товару/Работе/Услуге возникают непосредственно между Потребителем и Партнером. Все претензии к соответствующим Товару/Работе/Услуге, в том числе в отношении качества Товара/Работы/Услуги, сроков исполнения предъявляются Потребителем непосредственно Партнеру.
            <br><br>6.3. Агент заверяет Потребителей, что проверяет исполнение Партнерами своих гарантийных обязательств по реализованным Товарам/Работам/Услугам и осуществляет контроль за таким исполнением.
            <br><br>6.4. Агент не несет ответственности, не может выступать в качестве ответчика в суде и не возмещает убытки, возникшие у Потребителя из-за действия или бездействия Партнера.
            <br><br>6.5. Агент не несет ответственности за недостоверность сведений о Товарах/Работах/Услугах Партнера, предоставленных для размещения на Сайте/в Мобильном приложении.
            <br><br>6.6. Агент не несет ответственности за внесение (оплату) Потребителем дополнительных денежных средств Партнеру по требованию последнего, не указанному в предложении Партнера.
            <br><br>6.7. Ни при каких обстоятельствах Агент не несет ответственность перед Потребителями и/или третьими лицами за любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или потерянные данные, вред чести, достоинству и деловой репутации, вызванный использованием Сайта/Мобильного приложения и его содержимого, даже если Агент не предупреждал и не указывал на возможность такого вреда.
            <br><br>6.8. В случае форс-мажорных обстоятельств, стороны освобождаются от исполнения обязательств по настоящему Договору.
            <br><br><b>7. Заключительные положения</b>
            <br><br>7.1. Настоящий Договор, также предложения, представленные на Сайте/в Мобильном приложении, являются публичной офертой в соответствии со статьей 435 Гражданского кодекса РФ и частью 2 статьи 437 Гражданского кодекса РФ, которая становится действительной после ее размещения на Сайте/в Мобильном приложении.
            <br><br>7.2. Агент вправе в любое время вносить любые изменения, дополнения в текст настоящего Договора, прекращать его, размещать новый Договор.
            <br><br>7.3. Дополнение, изменение, прекращение настоящего Договора становятся действительными после их размещения на Сайте/в Мобильном приложении.
            <br><br>7.4. В случае возникновения противоречий, текст настоящего Договора, размещенный на Сайте/в Мобильном приложении, будет иметь преимущество по сравнению с любым иным текстом настоящего Договора.
            <br><br>7.5. Агент будет считать себя заключившим настоящий Договор со всяким лицом, предоставившим ее акцепт.
            <br><br>7.6. Все споры, вытекающие из правоотношений по настоящему Договору, разрешаются в порядке, предусмотренном действующим законодательством.
            <br><br>Право подлежащее применению — законодательство Российской Федерации.
            <br><br>7.7. Любая информация передается Потребителю следующими способами, по выбору Агента:
        </p>
        <br>
        <ul>
            <li>путем размещения соответствующей информации по месту нахождения Агента или;</li>
            <li>путем размещения на Сайте/в Мобильном приложении или;</li>
            <li>путем направления письменных уведомлений по указанным Потребителем адресу доставки корреспонденции и (или) адресу электронной почты, либо путем направления смс-сообщений по указанному Потребителем номеру телефона.</li>
        </ul>
        <br>
        <p>
            7.8. Агент прилагает все возможные усилия для обеспечения корректной работоспособности Сайта/Мобильного приложения, однако не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по настоящему Договору, а также возможные убытки, возникшие в том числе, но не ограничиваясь, в результате:
        </p>
        <br>
        <ul>
            <li>неправомерных действий третьих лиц, направленных на нарушения информационной безопасности или нормального функционирования Сайта/Мобильного приложения;</li>
            <li>сбоев в работе Сайта/Мобильного приложения, вызванных ошибками в коде, компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении Сайта/Мобильного приложения;</li>
            <li>отсутствия (невозможности установления, прекращения и пр.) интернет-соединений между сервером Потребителя и сервером Сайта/Мобильного приложения;</li>
            <li>проведения государственными и муниципальными органами, а также иными организациями мероприятий в рамках СОРМ (системы оперативно-розыскных мероприятий);</li>
            <li>установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в сети интернет и/или установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение настоящего Договора или его части;</li>
            <li>блокирования Сайта/Мобильного приложения из-за действий/бездействий и/или по решению органов государственной и муниципальной власти, их должностных лиц;</li>
            <li>выполнения работ Агентом на Сайте/в Мобильном приложении, в том числе профилактических работ в программно-аппаратном комплексе Сайта/Мобильного приложения с временным приостановлением работы Сайта/Мобильного приложения;</li>
            <li>наступления форс-мажорных обстоятельств, а также аварий или сбоев в программно-аппаратных комплексах третьих лиц, сотрудничающих с Агентом.</li>
        </ul>
        <br>
        <p>
            7.9. Вся текстовая информация и графические изображения Товаров/Работ/Услуг, размещаемых на Сайте/в Мобильном приложении, являются собственностью Агента и его Партнеров.
            <br><br>7.10. Настоящим Агент заявляет, что он является единственным обладателем всех исключительных прав на Сайт и Мобильное приложение, правомочен распоряжаться ими по своему усмотрению, в рамках действующего законодательства.
            <br><br>7.11. Материалы, контент Сайта/Мобильного приложения охраняются авторским правом и могут использоваться Пользователем исключительно для личного некоммерческого использования без отрыва от Сайта/Мобильного приложения.
            <br><br>7.12. Если иное не предусмотрено законодательством РФ, не допускается копирование, распространение, передача третьим лицам, опубликование или иное использование материалов, доступных на Сайте/в Мобильном приложении или полученных с Сайта/из Мобильного приложения, без получения письменного разрешения Агента или правообладателя.
            <br><br>7.13. В случае получения разрешения на копирование, распространение, опубликование или иное использование материалов Сайта/Мобильного приложения, охраняемых законодательством РФ, такое копирование, распространение, опубликование или иное использование не допускается без уведомления о принадлежности авторского права либо с изменением или исключением имени автора и/или товарного знака.
        </p>
    </div>
</div>

</template>

<script>
export default {
    name: 'offerPolicy',
    components: {
    },
    props: {
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    computed: {
    },
    watch: {

    },
    methods: {
        close() {
            try {
                const returnPath = this.$route.query.returnPath;
                const savedPosition = localStorage.getItem('formScrollPosition');
                if (returnPath) {
                    this.$router.push(returnPath).then(() => {
                        if (savedPosition) {
                            setTimeout(() => {
                                window.scrollTo({
                                    top: parseInt(savedPosition, 10),
                                    behavior: 'auto',
                                });
                                localStorage.removeItem('formScrollPosition');
                            }, 0);
                        }
                    });
                } else {
                    this.$router.push('/');
                }
            } catch (error) {
                // Fallback if navigation fails
                console.error('Navigation error:', error);
                this.$router.push('/');
            }
        },
    },
};
</script>

<style lang="sass" scoped>
.offer-conatiner
    width: 1024px
    margin: 0 auto
    padding-top: 88px
    padding-bottom: 184px
    +for-mobile
        width: 296px
        padding-top: 48px
        padding-bottom: 120px
    .popup__close
        position: fixed
        top: 32px
        right: 32px
        +for-mobile
            top: 8px
            right: 8px

</style>
