<!-- eslint-disable max-len -->
<template>
    <div class="cookie-container" v-if="!isCookiesAccepted">
        <div class="cookie-wrapper">
            <span class="smoke-1"></span>
            <span class="smoke-2"></span>
            <div class="cookie-info">
                <div class="cookie-text">
                    <p>
                        Разрешите нам сбор и обработку персональных данных с помощью Яндекс.Метрики, а также использование cookies
                    </p>
                </div>
                <submit-btn @click="acceptCookies" class="cookieBtn" :type="btnType">
                    <p class="btnText">РАЗРЕШАЮ</p>
                </submit-btn>
            </div>
        </div>
    </div>
</template>

<script>
import SubmitBtn from '@/components/forms/SubmitBtn.vue';

export default {
    name: 'TooltipComponent',
    components: {
        SubmitBtn,
    },
    data() {
        return {
            cookiesAccepted: this.$cookies.get('acceptCookie') === 'true',
        };
    },
    computed: {
        isCookiesAccepted() {
            return this.cookiesAccepted;
        },
    },
    watch: {
        cookiesAccepted(newValue) {
            if (newValue === 'true') {
                this.$emit('cookies-accepted');
            }
        },
    },
    methods: {
        acceptCookies() {
            this.$cookies.set('acceptCookie', 'true');
            this.cookiesAccepted = true;
        },
    },
};
</script>

<style scoped>

.cookie-container{
    width: 100%;
    padding: 8px 12px;
    max-width: 330px;
    height: 42px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 24px;
    right: 48px;
    overflow: hidden;
    background: linear-gradient(180deg, #373737 0%, rgba(89, 87, 35, 0) 100%), linear-gradient(180deg, rgba(89, 87, 35, 0) 0%, #373737 100%);
    z-index: 100000;
}
.cookie-container::after{
    content: "";
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    background: #1F1E1D;
    border-radius: 8px;
    z-index: -1;
}
.cookie-info{
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    gap: 12px;
}
.cookie-text{
    max-width: 218px;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 120%;
    color: #939393;
    z-index: 10;
}
p a{
    letter-spacing: 0.025em;
    color: #ECB63E;
}
.btnText{
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 120%;
    color: #0F0F0F;
}
.smoke-1{
    position: absolute;
    width: 309.83px;
    height: 166.88px;
    left: 324px;
    top: -35.77px;
    background-image: url('@/assets/img/smoke2.png');
    background-blend-mode: difference;
    transform: rotate(-0.04deg);
}
.smoke-2{
    position: absolute;
    background-image: url('@/assets/img/smoke2.png');
    background-repeat: no-repeat;
    width: 309.83px;
    height: 166.88px;
    left: -83px;
    top: -31.77px;
    background-blend-mode: difference;
    transform: rotate(-0.04deg) ;
    z-index: 1;

}

@media (max-width: 375px){
    .cookie-container{
        max-width: none;
        width: 100%;
        height: auto;
        border-radius: 0;
        bottom: 0;
        right: 50%;
        padding: 0;
        transform: translateX(50%);
        background: #1F1E1D;
        opacity: 1;
    }
    .cookie-container:after{
            display: none;
        }
.cookie-wrapper{
    width: 100%;
    padding: 4px 12px;
}
    .cookie-info{
        gap: 16px;
        width:100%;
    }
    .cookie-text{
        max-width: none;
        font-size: 7px;
        font-weight: 400;
        line-height: 8.35px;
    }
    .btnText{
    font-size: 7px;
    font-weight: 400;
    line-height: 8.4px;
    text-align: center;

}
    .smoke-1{
        position: absolute;
        width: 179.91px;
        height: 96.9px;
        left: 123px;
        top: 20px;
        background-image: url('@/assets/img/smoke6.png');
        background-blend-mode: difference;
        background-repeat: no-repeat;
        transform: rotate(0) scaleX(-1);
    }
    .smoke-2{
        position: absolute;
        width: 179.91px;
        height: 96.9px;
        left: 168px;
        top: 2px;
        background-image: url('@/assets/img/smoke6.png');
        background-blend-mode: difference;
        z-index: -1;
    }
}
</style>
