<template>
    <footer class="footer-landing" :class="`--footer-${currentTheme}`">
        <div class="footer-landing__inner">
            <div class="top">
                <img :src="logoPath" alt="" @click="scrollUp" :class="`--footer-logo-${currentTheme}`">
            </div>
        </div>
    </footer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { scrollTo } from '@/helpers/animate';

export default {
    name: 'FooterBlock',
    components: {
    },
    props: {
        currentTheme: {
            default() {
                return '';
            },
        },
        isFooter: {
            default() {
                return false;
            },
        },
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            isDesktop: (state) => state.isDesktop,
        }),
        logoPath() {
            const tm = this.currentTheme === 'ofram' ? `${this.currentTheme}-footer.png` : `${this.currentTheme}-footer.svg`;
            // eslint-disable-next-line
            return require(`@/assets/img/${tm}`);
        },
    },
    watch: {

    },
    methods: {
        ...mapActions('popups', [
            'open', 'close',
        ]),
        scrollUp() {
            if (!this.isFooter) {
                scrollTo('up');
            }
        },
    },
};
</script>

<style lang="sass">
.footer-landing
    width: 100%
    height: 344px
    background-position: center
    background-repeat: no-repeat
    background-color: $black1
    background-size: contain
    text-align: center
    margin-top: 216px
    border-radius: 80px 80px 0px 0px
    padding: 0 16px
    border: 4px solid rgba(255, 255, 255, 0.10)
    background: linear-gradient(102deg, rgba(255, 255, 255, 0.12) 2%, rgba(255, 255, 255, 0.02) 88%)
    box-shadow: 0px 30px 60px 0px rgba(159, 159, 159, 0.33)
    +for-mobile
        height: 224px
        border-radius: 24px 24px 0 0
        margin-top: 80px
    &__inner
        display: flex
        align-items: center
        justify-content: center
        overflow: hidden
        position: relative
        height: 100%
    &.--footer-zemskitchen
        .top
            &:after,&:before
                background-color: #A99D91
    .top
        width: auto
        position: relative
        display: flex
        align-items: center
        justify-content: center
        .--footer-logo-gdeokna
            +for-mobile
                width: 158px
        .--footer-logo-zemsmirrors
            +for-mobile
                width: 199px
        .--footer-logo-zemskitchen
            +for-mobile
                width: 202px
        img
            width: auto
            height: auto
            cursor: pointer
        &:before
            content: ''
            display: block
            width: 1000px
            height: 1px
            border-radius: 2px
            background-color: var(--accent)
            position: absolute
            left: calc(100% + 48px)
            top: calc(50%)
            +for-mobile
                content: none
        &:after
            content: ''
            display: block
            width: 1000px
            height: 1px
            border-radius: 2px
            background-color: var(--accent)
            position: absolute
            top: calc(50%)
            right: calc(100% + 48px)
            +for-mobile
                content: none
</style>
