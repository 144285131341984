<template>
  <div class="personal-conatiner">
      <div class="popup__close" @click="close" >
          <svg-icon :name="'close'" :width="32" :height="32"></svg-icon>
      </div>
  <div class="personal-text">
      <!-- eslint-disable max-len -->
      <h2>
        СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </h2>
      Настоящим я, действуя от своего имени, добровольно и не находясь под влиянием заблуждения, проставляя галочку напротив текста «Я даю согласие на обработку персональных данных», и нажимая на кнопку отправки заполненной формы, <b>даю свое согласие следующему оператору персональных данных:</b>
          <br><br>ИП Земсков Алексей Васильевич (ИНН 371400551536) (далее — «Оператор»);
          <br><br>на смешанную обработку, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), блокирование, удаление, уничтожение следующих персональных данных:
          <br><br>
          <table>
            <tr>
              <td><b>1.</b></td>
              <td><b>Общие категории персональных данных</b></td>
            </tr>
            <tr>
              <td>1.1</td>
              <td>фамилия, имя, отчество;</td>
            </tr>
            <tr>
              <td>1.2.</td>
              <td>контактный телефон;</td>
            </tr>
            <tr>
              <td>1.3.</td>
              <td>содержание поля «Комментарий»;</td>
            </tr>
            <tr>
              <td>1.4.</td>
              <td>сведения о предпочтительном способе связи;</td>
            </tr>
            <tr>
              <td>1.5.</td>
              <td>файлы cookies и данные, собираемые на сайте посредством агрегаторов статистики посетителей веб-сайтов.</td>
            </tr>
          </table>
          <br>
          <p>
            в целях обработки входящих заявок с сайта, размещенного по адресу: https://zemsbaza.ru/.
          </p>
          <br>Настоящим подтверждаю свое согласие на передачу Оператором вышеперечисленных персональных данных для достижения указанной выше цели партнерам Оператора, а также предоставляю свое согласие на обработку такими третьими лицами персональных данных в объемах, и на срок, указанных в настоящем согласии, но не более, чем требуется для обеспечения выполнения цели обработки персональных данных.
          <br><br>Срок действия настоящего согласия на обработку персональных данных начинается с момента его представления Оператору и прекращается по достижении целей обработки персональных данных, по получении отзыва согласия на обработку персональных данных или исключения Оператора из Единого государственного реестра индивидуальных предпринимателей.
          <br><br>Настоящее согласие может быть отозвано путем обращения к Оператору с соответствующим запросом по электронной почте director@zemsbaza.ru. В этом случае Оператор прекращает обработку персональных данных, а персональные данные подлежат уничтожению, если отсутствуют иные правовые основания для обработки, установленные законодательством Российской Федерации.
  </div>
</div>

</template>

<script>
export default {
    name: 'personalPolicy',
    components: {
    },
    props: {
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    computed: {
    },
    watch: {

    },
    methods: {
        async close() {
            // const savedPosition = localStorage.getItem('formScrollPosition');
            try {
                const returnPath = this.$route.query.returnPath;
                const savedPosition = localStorage.getItem('formScrollPosition');
                if (returnPath) {
                    this.$router.push(returnPath).then(() => {
                        if (savedPosition) {
                            setTimeout(() => {
                                window.scrollTo({
                                    top: parseInt(savedPosition, 10),
                                    behavior: 'auto',
                                });
                                localStorage.removeItem('formScrollPosition');
                            }, 0);
                        }
                    });
                } else {
                    this.$router.push('/');
                }
            } catch (error) {
                // Fallback if navigation fails
                console.error('Navigation error:', error);
                this.$router.push('/');
            }
        },
    },
};
</script>

<style lang="sass" scoped>
.personal-conatiner
  width: 895px
  margin: 0 auto
  padding-top: 88px
  padding-bottom: 184px
  +for-mobile
      width: 296px
      padding-top: 48px
      padding-bottom: 120px

  .popup__close
      position: fixed
      top: 32px
      right: 32px
      +for-mobile
          top: 8px
          right: 8px

  .personal-text
      font-style: normal
      font-weight: 400
      font-size: 20px
      line-height: 24px
      color: #606060
      table
        border-collapse: separate
        border-spacing: 0
        width: 100%
        border: 1px solid #D8D8D8
        border-radius: 12px
        overflow: hidden
        margin-top: 6px
        margin-bottom: 6px
        +for-mobile
          margin-top: -10px
          margin-bottom: -5px
      td
        padding: 0 11px
        height: 55px
        border: 1px solid #D8D8D8
        +for-mobile
          height: 42px
          padding: 0
          padding-left: 7px
      tr
        td
          &:last-child
            padding-left: 14px
            +for-mobile
              padding: 11px 7px
        &:first-child td
          &:first-child
            padding: 0 11px
            text-align: center
            border-top-left-radius: 12px
            +for-mobile
              height: 60px
              padding: 0 7px
          &:last-child
            border-top-right-radius: 12px
        &:last-child td
          height: 80px
          &:first-child
            border-bottom-left-radius: 12px
            padding:  24px 11px
            +for-mobile
            padding: 0 7px
          &:last-child
            border-bottom-right-radius: 12px

      +for-mobile
          font-size: 16px
          line-height: 18px

      h2
          font-family: 'BebasNeue', sans-serif
          font-style: normal
          font-weight: 700
          font-size: 48px
          line-height: 56px
          color: #0F0F0F
          margin-bottom: 32px
          +for-mobile
              font-size: 32px
              line-height: 32px
              letter-spacing: 0.025em
              margin-bottom: 15px

      h4
          text-transform: uppercase
          font-weight: 700
      b
          font-weight: 700
      br
        +for-mobile
          content: ''
          height: 10px
          display: block
      ul
          padding-left: 1rem
          li
              list-style-type: disc
              list-style-position: outside
</style>
