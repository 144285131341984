import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import PrivacyPolicy from '@/components/lawModals/PrivacyPolicy.vue';
import PersonalData from '@/components/lawModals/PersonalData.vue';
import ContractOffer from '@/components/lawModals/ContractOffer.vue';
import app from '../main';
import MainComponent from '../views/Main.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: MainComponent,
        components: {
            default: MainComponent,
            header: () => import('@/components/MainHeader.vue'),
            footer: () => import('@/components/FooterBlock.vue'),
        },
    },
    {
        path: '/catalog/',
        name: 'catalog',
        components: {
            default: MainComponent,
            header: () => import('@/components/MainHeader.vue'),
            footer: () => import('@/components/FooterBlock.vue'),
        },
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        components: {
            default: PrivacyPolicy,
        },
    },
    {
        path: '/personal-data',
        name: 'personal-data',
        components: {
            default: PersonalData,
        },
    },
    {
        path: '/contract-offer',
        name: 'contract-offer',
        components: {
            default: ContractOffer,
        },
    },
    {
        path: '/catalog/:code/',
        name: 'catalog',
        components: {
            default: MainComponent,
            header: () => import('@/components/MainHeader.vue'),
            footer: () => import('@/components/FooterBlock.vue'),
        },
    },
    {
        path: '/catalog/:code/:id/',
        name: 'offer-detail',
        components: {
            default: () => import(/* webpackChunkName: "main" */ '../views/OfferDetail.vue'),
            header: () => import('@/components/MainHeader.vue'),
            footer: () => import('@/components/FooterBlock.vue'),
        },
    },
    {
        path: '/catalog/:code/:id/:landing',
        name: 'offer-landing',
        component: () => import(/* webpackChunkName: "main" */ '../views/LandingPage.vue'),
    },
    {
        path: '/catalog/:code/:id/zemsmirrors-optima-td',
        name: 'offer-landing-404',
        components: {
            default: () => import(/* webpackChunkName: "main" */ '../views/ErrorPage.vue'),
            header: () => import('@/components/MainHeader.vue'),
            footer: () => import('@/components/FooterBlock.vue'),
        },
    },
    {
        path: '/ui-board/',
        name: 'ui-board',
        component: () => import(/* webpackChunkName: "main" */ '../views/UiBoard.vue'),
    },
    {
        path: '/404page/',
        name: '404Page',
        components: {
            default: () => import(/* webpackChunkName: "main" */ '../views/ErrorPage.vue'),
            header: () => import('@/components/MainHeader.vue'),
            footer: () => import('@/components/FooterBlock.vue'),
        },
    },
    {
        path: '/:pathMatch(.*)*',
        components: {
            default: () => import(/* webpackChunkName: "main" */ '../views/ErrorPage.vue'),
            header: () => import('@/components/MainHeader.vue'),
            footer: () => import('@/components/FooterBlock.vue'),
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return new Promise((resolve) => {
                app.config.globalProperties.emitter.on('triggerScrollOffersList', () => {
                    resolve(savedPosition);
                });
            });
        }
        if (to.name === 'catalog') {
            return store.state.isDesktop ? { el: '#scroll-block' } : false;
        }
        return { top: 0 };
    },
});

export default router;
